import Immutable from 'immutable';
import {
  REQUEST_FAIL,
  REQUEST_FETCHING,
  REQUEST_SENDING,
  REQUEST_SUCCESS,
} from 'constants/requestStatus';
import * as constants from './constants';
import * as types from './types';

const initialState = Immutable.fromJS({
  requestStatus: {
    putBroadband: null,
    getBroadband: null,
  },
  homephoneOptions: {
    landlineNumber: undefined,
    isVirginMedia: undefined,
    selectedCallPackageId: constants.NO_CALL_PACKAGE_ID,
    callPackages: [],
  },
  tariffs: [],
  hasGoldDiscount: false,
  selectedTariff: null,
  selectedRouterName: null,
  selectedEeroDevices: constants.DEFAULT_NUMBER_OF_EEROS,
  broadbandInfo: false,
  eeroDialogOpen: false,
  technology: null,
  requiresNewLine: false,
});

export default function counter(state = initialState, action) {
  switch (action.type) {
    case constants.GET_BROADBAND_REQUEST:
      return state.mergeDeep({
        requestStatus: { getBroadband: REQUEST_FETCHING },
      });
    case constants.GET_BROADBAND_REQUEST_SUCCESS:
      state = state.merge({
        tariffs: action.tariffs,
        hasGoldDiscount: action.hasGoldDiscount,
        requiresNewLine: action.requiresNewLine,
      });
      return state.mergeDeep({
        requestStatus: { getBroadband: REQUEST_SUCCESS },
        homephoneOptions: {
          callPackages: action.callPackages,
        },
      });
    case constants.GET_BROADBAND_REQUEST_FAILURE:
      return state.mergeDeep({
        requestStatus: { getBroadband: REQUEST_FAIL },
      });

    case constants.UPDATE_ROUTER_SELECTION:
      return state.merge({
        selectedRouterName: action.routerName,
      });
    case constants.UPDATE_TARIFF_SELECTION:
      return state.merge({
        selectedTariff: action.tariff,
        selectedRouterName: null,
      });
    case constants.UPDATE_SELECTED_EERO_DEVICES:
      return state.merge({
        selectedEeroDevices: action.selectedEeroDevices,
      });

    case constants.PUT_BROADBAND_REQUEST:
      return state.mergeDeep({
        requestStatus: {
          putBroadband: REQUEST_SENDING,
        },
      });
    case constants.PUT_BROADBAND_SUCCESS:
      return state.mergeDeep({
        requestStatus: {
          putBroadband: REQUEST_SUCCESS,
        },
      });
    case constants.PUT_BROADBAND_FAILURE:
      return state.mergeDeep({
        requestStatus: {
          putBroadband: REQUEST_FAIL,
        },
      });

    case constants.SET_BROADBAND_MORE_INFO:
      return state.mergeDeep({
        broadbandInfo: action.open,
      });
    case constants.SET_EERO_DIALOG_OPEN:
      return state.mergeDeep({
        eeroDialogOpen: action.open,
      });

    case constants.CLEAR_BROADBAND_OPTIONS:
      return initialState;

    case types.BROADBAND_SET_TECHNOLOGY:
      return state.set('technology', action.technology);

    case constants.UPDATE_HOMEPHONE_OPTION:
      return state.setIn(['homephoneOptions', action.option], action.value);

    case types.PRESELECT_SUCCESS:
      return state.mergeDeep(action.preselectState);

    default:
      return state;
  }
}
